import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";

function Business() {
  let [bannerGroup, setBannerGroup] = useState([]);
  let [bannerImage, setBannerImage] = useState();
  let [bannerHeading, setBannerHeading] = useState();
  let [bannerButtonLabel, setbannerButtonLabel] = useState();
  let [bannerBelowSlider, setbannerBelowSlider] = useState([]);
  let [copyImage, setCopyImage] = useState([]);
  let [copyImgHeading, setcopyImgHeading] = useState();
  let [copyImgLeftContent, setcopyImgLeftContent] = useState();
  let [copyImgRightImg, setcopyImgRightImg] = useState();
  let [logoCopy, setlogoCopy] = useState([]);
  let [textButton, settextButton] = useState([]);
  let [purchaseButtonUrl, setPurchaseButtonUrl] = useState();
  let [purchaseButtonTitle, setPurchaseButtonTitle] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/businessPage`
      );
      let businessPageData = await response.json();

      setBannerGroup(businessPageData.banner_group);
      setbannerBelowSlider(businessPageData.bannerBelowSlider);
      setCopyImage(businessPageData.copy_image);
      setlogoCopy(businessPageData.logo_copy);
      settextButton(businessPageData.text_button);
    };
    setTimeout(() => {
      fetchData();
    }, 1000);
  }, []);

  useEffect(() => {
    if (bannerGroup) {
      setBannerImage(bannerGroup.banner_image);
      setBannerHeading(bannerGroup.banner_heading);
      setbannerButtonLabel(bannerGroup.banner_button_label);
    }
  }, [bannerGroup]);

  useEffect(() => {}, [bannerBelowSlider]);

  useEffect(() => {
    if (copyImage) {
      setcopyImgHeading(copyImage.heading);
      setcopyImgLeftContent(copyImage.left_content);
      setcopyImgRightImg(copyImage.right_image);
    }
  }, [copyImage]);

  useEffect(() => {}, [logoCopy]);
  useEffect(() => {
    if (textButton && textButton.button) {
      setPurchaseButtonUrl(textButton.button.url);
      setPurchaseButtonTitle(textButton.button.title);
    }
  }, [textButton]);

  useEffect(() => {
    AOS.init({ startEvent: "load" });
  }, []);

  //////////////banner below slider ////////////

  const bannerSettings = {
    nav: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    lazyLoad: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>
          Business Solutions stellen ganz einfach Talente ein – Jobwish
        </title>
        <meta
          name="description"
          content="Stellen Sie mit Jobwish Top-Talente für Ihr Unternehmen ein, finden Sie tolle Kandidaten und erweitern Sie noch heute Ihr Team – Vernetzen Sie sich noch heute!"
        />
      </Helmet>
      <section className="business_main">
        <img src={bannerImage} alt="" loading="eager" />
        <div className="container">
          <div
            className="business_main_content"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-offset="200"
            data-aos-delay="500"
          >
            <h1>{bannerHeading}</h1>
            <Link to="/contact-us" className="btn btn-red">
              {bannerButtonLabel}
            </Link>
          </div>
        </div>
      </section>

      <section className="banner_iconslider">
        <div className="container">
          <div className="row">
            {bannerBelowSlider.length > 0 ? (
              <Slider {...bannerSettings}>
                {bannerBelowSlider?.map((img, i) => (
                  <div className="col-md-3" key={i}>
                    <div className="banner_iconslider_logo">
                      <img src={img} alt="business logo" />
                    </div>
                  </div>
                ))}
              </Slider>
            ) : null}
          </div>
        </div>
      </section>

      <section className="why_business">
        <div className="container">
          <div className="row-new ">
            <div className="col-md-12 col-12">
              <div className="why_business_head">{copyImgHeading}</div>
            </div>
            <div className="col-md-6">
              <div
                className="why_business_text"
                dangerouslySetInnerHTML={{ __html: copyImgLeftContent }}
              />
            </div>
            <div className="col-md-6">
              <div
                className="why_business_img"
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-offset="200"
                data-aos-delay="500"
              >
                <img src={copyImgRightImg} alt="why business" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {logoCopy.length > 0
        ? logoCopy.map((item, i) =>
            i % 2 === 0 ? (
              <section className="business_integrate" key={i}>
                <div className="container">
                  <div className="row-new">
                    <div className="col-md-6">
                      <div
                        className="business_integrate_img"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                        data-aos-offset="200"
                        data-aos-delay="500"
                      >
                        <img src={item.image} alt="integrate business" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="business_integrate_txt"
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      />
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              <section className="business_recruit" key={i}>
                <div className="container">
                  <div className="row-new">
                    <div className="col-md-6">
                      <div
                        className="business_recruit_txt"
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      />
                    </div>
                    <div className="col-md-6">
                      <div
                        className="business_recruit_img"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                        data-aos-offset="200"
                        data-aos-delay="500"
                      >
                        <img src={item.image} alt="recruit without risk" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )
          )
        : null}

      <section className="business_product">
        <div className="container">
          <div className="row-new">
            <div className="col-md-12">
              <div className="business_product_txt">
                <h2>{textButton.heading}</h2>
                <a
                  href={purchaseButtonUrl}
                  className="btn"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-offset="200"
                  data-aos-delay="500"
                >
                  {purchaseButtonTitle}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Business;
