import React, { useEffect, useState } from "react";
// import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import { Helmet } from "react-helmet";

function TopEmployer() {
  let [topEmployer, setTopEmployer] = useState([]);
  let [topempStatus, setTopEmpStatus] = useState(false);
  const [bannerTitle, setBannerTitle] = useState();
  const [bannerImg, setBannerImg] = useState();

  useEffect(() => {
    const topEmployerlist = async () => {
      // try {
      let topEmpResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/web-TopEmployer`
      );
      let topEmpData = await topEmpResponse.json();
      setTopEmployer(topEmpData.data.employerDetail);
      let StatusValue = topEmpData.data.status;
      if (StatusValue === 200) {
        setTopEmpStatus(true);
      }
      // } catch (error) {
      //   console.log("Error: ", error);
      // }
    };
    setTimeout(() => {
      topEmployerlist();
    }, 200);
  }, []);

  useEffect(() => {
    const bannerDetail = async () => {
      let bannerResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/pages/7092`
      );
      let bannerData = await bannerResponse.json();
      setBannerTitle(bannerData.title.rendered);
    };
    bannerDetail();
  }, []);

  useEffect(() => {
    const ACL_page_images = async () => {
      let ACLpageimagesResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}/wp-json/jobwish/v1/imagesForWeb`
      );
      let imagesResponse = await ACLpageimagesResponse.json();
      setBannerImg(imagesResponse.data.ACL_page);
    };
    ACL_page_images();
  }, []);

  useEffect(() => {}, [bannerImg, bannerTitle]);

  useEffect(() => {
    AOS.init({ startEvent: "load" });
  }, []);

  ///////////// Top employer slider

  const topEmpSettings = {
    slidesToShow: 3.4,
    slidesToScroll: 1,
    infinite: true,
    loop: true,
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    arrows: false,
    lazyLoad: false,
    responsive: [
      {
        breakpoint: 1940,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Helmet>
        <title>Top-Arbeitgeber in der Schweiz entdecken</title>
        <meta
          name="description"
          content="Erfahre, welche Unternehmen die besten Arbeitgeber sind. Jobwish hilft dir, Top-Firmen in der Schweiz zu finden und deine Karriere zu planen."
        />
      </Helmet>
      <section className="job-list-hero blog-hero ">
        {/* <LazyLoad className="AllCompImg blog-hero"> */}
        <img src={bannerImg} alt="" loading="eager" />
        {/* </LazyLoad> */}
        <div className="bnr-blog-content">
          <h1>{bannerTitle}</h1>
        </div>
      </section>
      <section className="new-jobs-section">
        <div className="container">
          <div className="new-jobs">
            {/* <h2 className="top-employer-heading">Top Arbeitgeber</h2> */}
            <div className="items">
              {topempStatus ? (
                <Slider {...topEmpSettings}>
                  {topEmployer.map((item, i) => (
                    <div
                      className="item-card aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay={`${i * 2}00`}
                      data-aos-offset="100"
                      key={i}
                    >
                      <a
                        href={`${process.env.REACT_APP_HOST_URL}employer/${item.employer_slug}`}
                      >
                        <div className="jobs" key={i}>
                          <div className="image-wrapper">
                            <img
                              src={
                                item.profileIMG
                                  ? item.profileIMG
                                  : `${process.env.REACT_APP_HOST_URL}wp-content/uploads/2024/07/JobwishLogo.png`
                              }
                              alt=""
                            />
                          </div>

                          <div className="job-text">
                            <h3>{item.employer_Name}</h3>

                            <p
                              dangerouslySetInnerHTML={{
                                __html: item.employer_bio
                                  .split(" ")
                                  .slice(0, 50)
                                  .join(" "),
                              }}
                            />
                            <p>
                              <strong>Kontakt:</strong> {item.employer_contact}
                            </p>
                            <p>
                              <strong>Email:</strong> {item.employer_email}{" "}
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div className="topempstatusmsg">
                  {topEmployer}
                  <div className="error">
                    <Link to={`/`}>{<span>Geh zurück</span>}</Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TopEmployer;
