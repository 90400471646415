import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import CountUp from "react-countup";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
// import { a } from "@react-spring/web";

// import homebanner from '../assets/images/slider-top.webp';

function Home() {
  const [homeData, setHomeData] = useState();
  let [bannerImage, setBannerImage] = useState([]);
  let [jobNumber, setJobnumber] = useState();
  let [bannerHeading, setBannerHeading] = useState();
  let [bannerContent, setBannerContent] = useState();
  let [preHeadingSection, setpreHeadingSection] = useState();
  let [headingSection, setheadingSection] = useState();
  let [processSection, setprocessSection] = useState();
  let [imageSlider, setImageSlider] = useState([]);
  let [preAboutHeading, setPreAboutHeading] = useState();
  let [aboutHeading, setAboutHeading] = useState();
  let [aboutContent, setAboutContent] = useState();
  let [aboutImage, setAboutImage] = useState();
  let [interViewSectionImage, setInterViewSectionImage] = useState();
  let [interViewSectionheading, setInterViewSectionheading] = useState();
  let [preHeading, setPreHeading] = useState();
  let [heading, setHeading] = useState();
  let [content, setContent] = useState();
  let [appImage, setAppImage] = useState();
  const [jobType, setJobType] = useState([]);
  let [jobTitle, setJobTitle] = useState();
  let [jobLocation, setJobLocation] = useState();
  let [jobSearchtype, setJobSearchtype] = useState();
  let [jobByDescOrder, setJobByDescOrder] = useState([]);
  let [topEmployer, setTopEmployer] = useState([]);
  let [topEmployerStatus, setTopEmployerStatus] = useState(false);
  let [employerCount, setEmployerCount] = useState();
  let [candidateCount, setCandidateCount] = useState();
  let [searchJobBtn, setSearchJobBtn] = useState(true);
  let [searchCandidateBtn, setSearchCandidateBtn] = useState(false);
  let [searchEmployerBtn, setsearchEmployerBtn] = useState(false);
  let [sectorTitle, setSectorTitle] = useState([]);
  let [industryTitle, setIndustryTitle] = useState([]);
  let [sectorName, setSectorName] = useState();
  let [industryName, setIndustryName] = useState();
  let [appIOSLink, setAppIOSLink] = useState();
  let [appAndroidLink, setAppAndroidLink] = useState();
  let [iosAppLinkImg, setiosAppLinkImg] = useState();
  let [androidAppLinkImg, setandroidAppLinkImg] = useState();
  let [employertitle, setEmployerTitle] = useState();
  const [sectorDataFetched, setSectorDataFetched] = useState(false);
  const [skillDataFetched, setSkillDataFetched] = useState(false);
  const [jobtypeDataFetched, setJobtypeDataFetched] = useState(false);

  useEffect(() => {
    const home_Page_Images = async () => {
      let homePagesImagesResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/imagesForWeb`
      );
      let $imagesResponse = await homePagesImagesResponse.json();
      // console.log($imagesResponse);
      setBannerImage($imagesResponse.data.Home_Page.homeBannerSlides);
      setImageSlider($imagesResponse.data.Home_Page.homeFirstSectionSlides);
      setAboutImage($imagesResponse.data.Home_Page.home_aboutSectionImg);
      setInterViewSectionImage(
        $imagesResponse.data.Home_Page.home_interviewSectionImg
      );
      setAppImage($imagesResponse.data.Home_Page.home_MobileImage);
      setiosAppLinkImg($imagesResponse.data.Home_Page.appIOSImg);
      setandroidAppLinkImg($imagesResponse.data.Home_Page.appAndroidImg);
    };
    setTimeout(() => {
      home_Page_Images();
    }, 400);
  }, []);

  useEffect(() => {}, [bannerImage]);
  useEffect(() => {}, [imageSlider]);
  useEffect(() => {}, [aboutImage]);
  useEffect(() => {}, [interViewSectionImage]);
  useEffect(() => {}, [appImage]);

  useEffect(() => {
    const employerCandidateCount = async () => {
      let countResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/employerCandidateCount`
      );
      let countRes = await countResponse.json();
      setJobnumber(countRes.job_count);
      setEmployerCount(countRes.employer_count);
      setCandidateCount(countRes.candidate_count);
    };
    setTimeout(() => {
      employerCandidateCount();
    }, 500);
  }, []);

  useEffect(() => {
    const homeDataResponse = async () => {
      // try {
      const dataResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/homepage`
      );
      const data = await dataResponse.json();
      setHomeData(data);
      // } catch (error) {
      //   console.log("Error :", error);
      // }
    };
    setTimeout(() => {
      homeDataResponse();
    }, 600);
  }, []);

  useEffect(() => {
    if (homeData && homeData.data) {
      // console.log(homeData);
      setBannerHeading(homeData.data.banner_heading);
      setBannerContent(homeData.data.banner_content);
      setpreHeadingSection(homeData.data.pre_heading_section);
      setheadingSection(homeData.data.heading_section);
      setprocessSection(homeData.data.process);
      setAboutHeading(homeData.data.about_heading);
      setPreAboutHeading(homeData.data.pre_about_heading);
      setAboutContent(homeData.data.about_content);
      setInterViewSectionheading(homeData.data.interview_section_heading);
      setPreHeading(homeData.data.pre_heading);
      setHeading(homeData.data.heading);
      setContent(homeData.data.content);
      setAppIOSLink(homeData.data.app_ios_link);
      setAppAndroidLink(homeData.data.app_android_link);
    }
  }, [homeData]);

  const jobTypeDetail = async () => {
    let jobTypeResponse = await fetch(
      `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/web-jobType`
    );
    let jobTypeData = await jobTypeResponse.json();
    setJobtypeDataFetched(true);
    setJobType(jobTypeData.List || []);
  };

  const handelJobtypeClick = () => {
    if (!jobtypeDataFetched) {
      jobTypeDetail();
    }
  };

  useEffect(() => {
    const JobByDesc = async () => {
      let jobSkillResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/web-jobByDescOrder`
      );
      let jobSkillData = await jobSkillResponse.json();
      setJobByDescOrder(jobSkillData.data);
      if (Array.isArray(jobSkillData.data)) {
        setJobByDescOrder(jobSkillData.data);
      } else {
        // Handle empty job list case (e.g., job list is empty or API error response)
        setJobByDescOrder([]);
      }
    };
    setTimeout(() => {
      JobByDesc();
    }, 800);
  }, []);

  useEffect(() => {
    const IntrViewQues = async () => {
      let topemployerResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/web-TopEmployer`
      );
      let topemployerData = await topemployerResponse.json();
      setTopEmployer(topemployerData.data.employerDetail);
      let stausaction = topemployerData.data.status;
      if (stausaction === 200) {
        setTopEmployerStatus(true);
      }
    };
    setTimeout(() => {
      IntrViewQues();
    }, 700);
  }, []);

  const allSector = async () => {
    let sectorResponse = await fetch(
      `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/jobSectorList`
    );
    let sectorList = await sectorResponse.json();
    // console.log(sectorList);
    setSectorDataFetched(true);
    setSectorTitle(sectorList.List);
  };

  const handleDropdownClick = () => {
    if (!sectorDataFetched) {
      allSector();
    }
  };

  const IndustryDetail = async () => {
    let industryResponse = await fetch(
      `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/skill-list`
    );
    let industryList = await industryResponse.json();
    setSkillDataFetched(true);
    setIndustryTitle(industryList.skill);
  };

  const handelskillDropdownClick = () => {
    if (!skillDataFetched) {
      IndustryDetail();
    }
  };

  useEffect(() => {}, [industryTitle]);

  //////////// Search Btn toggle /////////

  let toggleJobSearch = () => {
    setSearchJobBtn(true);
    setSearchCandidateBtn(false);
    setsearchEmployerBtn(false);
  };

  let toggleCandidateSearch = () => {
    setSearchCandidateBtn(true);
    setSearchJobBtn(false);
    setsearchEmployerBtn(false);
  };

  let toggleEmployerSearch = () => {
    setsearchEmployerBtn(true);
    setSearchCandidateBtn(false);
    setSearchJobBtn(false);
  };

  ///////////// banner slider setting

  const bannerSettings = {
    nav: true,
    dots: true,
    infinite: true,
    speed: 6000,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    lazyLoad: false,
  };

  //////////////// Functionality section slider ////
  const funcSettings = {
    nav: true,
    dots: true,
    infinite: true,
    speed: 6000,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    lazyLoad: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };

  useEffect(() => {
    AOS.init({ startEvent: "load" });
  }, []);

  return (
    <>
      <Helmet>
        <title>Finde Jobs in der Schweiz | Bewerbe dich online – Jobwish</title>
        <meta
          name="description"
          content="Finde mit Jobwish die besten Stellenangebote in der Schweiz. Starte noch heute deine Karriere – bewirb dich jetzt!"
        />
      </Helmet>
      <section className="hero">
        <div className="hero__slider">
          {/* <LazyLoad className="home_banner">       */}
          {bannerImage.length > 0 ? (
            <Slider {...bannerSettings}>
              {bannerImage
                .filter((img) => img !== null)
                .map((ImgLink, index) => (
                  <div className="hero__slide" key={index}>
                    <img
                      src={ImgLink}
                      alt={`home-slider-${index}`}
                      loading="eager"
                    />
                  </div>
                ))}
            </Slider>
          ) : (
            <div className="hero__slider-static">
              <img
                src={`${process.env.REACT_APP_STAGING_DIR_PATH}/react-app/build/jobwish-home-banner1-1899x1150.jpg`}
                // src={homebanner}
                alt=""
              />
            </div>
            // null
          )}

          {/* </LazyLoad> */}
        </div>
        <div className="container overlay-banner-content ">
          <div className="row">
            <div className="btn-wrp">
              <button
                onClick={toggleJobSearch}
                className={`jobSearch ${searchJobBtn ? "Active" : ""}`}
              >
                Job&nbsp;suchen
              </button>
              <button
                onClick={toggleCandidateSearch}
                className={`candidateSearch ${
                  searchCandidateBtn ? "Active" : ""
                }`}
              >
                Kandidaten suchen
              </button>
              <button
                onClick={toggleEmployerSearch}
                className={`employerSearch ${
                  searchEmployerBtn ? "Active" : ""
                }`}
              >
                Arbeitgeber suchen
              </button>
            </div>
            {searchJobBtn ? (
              <form className="search-container Active">
                <div className="input">
                  <input
                    type="text"
                    className="messageHere"
                    placeholder="Jobtitel für Schlüsselwort"
                    onChange={(e) => {
                      setJobTitle(e.target.value);
                    }}
                  />
                  {/* <input
                  type="search"
                  className="messageHere mobile-view"
                  placeholder="Search Here..."
                /> */}
                </div>

                <div className="input">
                  <input
                    type="text"
                    className="sBtn-text"
                    placeholder="Ort eingeben"
                    onChange={(e) => {
                      setJobLocation(e.target.value);
                    }}
                  />
                </div>

                <div className="select-menu-2">
                  <div className="select-btn-2">
                    <label>jobType</label>
                    <select
                      name="jobType"
                      onChange={(e) => {
                        setJobSearchtype(e.target.value);
                      }}
                      onClick={handelJobtypeClick}
                      id="jobType"
                    >
                      <option value="Select">Jobtyp</option>
                      {jobType?.map((typeList, i) => (
                        <option value={typeList} key={i}>
                          {typeList}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="search-btn">
                  <Link
                    className="search-form-btn"
                    type="submit"
                    to={`/searchjobresult?jobTitle=${jobTitle}&jobLocation=${jobLocation}&jobType=${jobSearchtype}`}
                    aria-label="jobSearchBtn"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="72"
                      height="72"
                      viewBox="0 0 74 74"
                      fill="none"
                    >
                      <rect width="74" height="74" rx="37" fill="#846ADC" />
                      <path
                        d="M54.7537 53.2255C54.7435 53.2297 54.7265 53.2305 54.7231 53.2381C54.1119 54.4949 53.0688 54.6643 52.0598 53.6693C48.9238 50.5761 45.787 47.4845 42.6588 44.3845C42.4624 44.1899 42.3451 44.1555 42.1088 44.3375C40.3397 45.6992 38.3404 46.5708 36.1327 46.9459C33.3308 47.4216 30.6216 47.0969 28.0323 45.9123C25.2882 44.6563 23.1724 42.7183 21.7103 40.1133C20.3688 37.7223 19.8146 35.1492 20.0543 32.415C20.2974 29.6456 21.3116 27.1782 23.0848 25.0355C25.2449 22.4246 28.0255 20.8096 31.3944 20.2039C31.8475 20.1225 32.3065 20.0671 32.7621 20C33.4405 20 34.1197 20 34.7981 20C34.8822 20.0738 34.9893 20.0579 35.088 20.0654C36.9726 20.2215 38.739 20.7828 40.3814 21.6855C43.0268 23.1402 44.9973 25.2335 46.269 27.9542C47.4591 30.5005 47.783 33.1625 47.3044 35.916C46.9261 38.0948 46.0387 40.0655 44.6649 41.8139C44.4881 42.0388 44.4983 42.1562 44.7032 42.3576C47.8043 45.4038 50.8943 48.461 53.9929 51.5115C54.3074 51.8211 54.5964 52.1382 54.7528 52.5552V53.2247L54.7537 53.2255ZM44.6054 33.5778C44.6139 27.6874 39.7362 22.8726 33.7593 22.8718C27.7815 22.8718 22.9131 27.674 22.9123 33.5753C22.9106 39.4724 27.7841 44.283 33.7576 44.2813C39.7345 44.2796 44.5969 39.4824 44.6054 33.5778Z"
                        fill="white"
                      />
                    </svg>
                  </Link>
                </div>
              </form>
            ) : null}
            {searchCandidateBtn ? (
              <form className="search-container candidate-search Active">
                <div className="input">
                  <select
                    name="sector"
                    onChange={(e) => {
                      setSectorName(e.target.value);
                    }}
                    onClick={handleDropdownClick}
                    id="sectorType"
                  >
                    <option value="Select">Sektorliste</option>
                    {sectorTitle?.map((typeList, i) => (
                      <option value={typeList} key={i}>
                        {typeList}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input">
                  <select
                    name="industry"
                    onChange={(e) => {
                      setIndustryName(e.target.value);
                    }}
                    onClick={handelskillDropdownClick}
                    id="skilltype"
                  >
                    <option value="Select">Fähigkeitenliste</option>
                    {industryTitle?.map((typeList, i) => (
                      <option value={typeList} key={i}>
                        {typeList}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="select-menu-2">
                  <div className="select-btn-2">
                    <input
                      type="text"
                      className="messageHere"
                      placeholder="Standort"
                      onChange={(e) => {
                        setJobLocation(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="search-btn">
                  <Link
                    className="search-form-btn"
                    type="submit"
                    to={`/searchcandidateresult?sectorName=${sectorName}&industryName=${industryName}&jobLocation=${jobLocation}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="72"
                      height="72"
                      viewBox="0 0 74 74"
                      fill="none"
                    >
                      <rect width="74" height="74" rx="37" fill="#846ADC" />
                      <path
                        d="M54.7537 53.2255C54.7435 53.2297 54.7265 53.2305 54.7231 53.2381C54.1119 54.4949 53.0688 54.6643 52.0598 53.6693C48.9238 50.5761 45.787 47.4845 42.6588 44.3845C42.4624 44.1899 42.3451 44.1555 42.1088 44.3375C40.3397 45.6992 38.3404 46.5708 36.1327 46.9459C33.3308 47.4216 30.6216 47.0969 28.0323 45.9123C25.2882 44.6563 23.1724 42.7183 21.7103 40.1133C20.3688 37.7223 19.8146 35.1492 20.0543 32.415C20.2974 29.6456 21.3116 27.1782 23.0848 25.0355C25.2449 22.4246 28.0255 20.8096 31.3944 20.2039C31.8475 20.1225 32.3065 20.0671 32.7621 20C33.4405 20 34.1197 20 34.7981 20C34.8822 20.0738 34.9893 20.0579 35.088 20.0654C36.9726 20.2215 38.739 20.7828 40.3814 21.6855C43.0268 23.1402 44.9973 25.2335 46.269 27.9542C47.4591 30.5005 47.783 33.1625 47.3044 35.916C46.9261 38.0948 46.0387 40.0655 44.6649 41.8139C44.4881 42.0388 44.4983 42.1562 44.7032 42.3576C47.8043 45.4038 50.8943 48.461 53.9929 51.5115C54.3074 51.8211 54.5964 52.1382 54.7528 52.5552V53.2247L54.7537 53.2255ZM44.6054 33.5778C44.6139 27.6874 39.7362 22.8726 33.7593 22.8718C27.7815 22.8718 22.9131 27.674 22.9123 33.5753C22.9106 39.4724 27.7841 44.283 33.7576 44.2813C39.7345 44.2796 44.5969 39.4824 44.6054 33.5778Z"
                        fill="white"
                      />
                    </svg>
                  </Link>
                </div>
              </form>
            ) : null}
            {searchEmployerBtn ? (
              <form className="search-container search-employer Active">
                <div className="input">
                  <input
                    type="text"
                    className="messageHere"
                    placeholder="Name des Arbeitgebers"
                    onChange={(e) => {
                      setEmployerTitle(e.target.value);
                    }}
                  />
                </div>
                <div className="input">
                  <input
                    type="text"
                    className="messageHere"
                    placeholder="Standort"
                    onChange={(e) => {
                      setJobLocation(e.target.value);
                    }}
                  />
                </div>

                <div className="search-btn">
                  <Link
                    className="search-form-btn"
                    type="submit"
                    to={`/searchemployerresult?employerName=${employertitle}&jobLocation=${jobLocation}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="72"
                      height="72"
                      viewBox="0 0 74 74"
                      fill="none"
                    >
                      <rect width="74" height="74" rx="37" fill="#846ADC" />
                      <path
                        d="M54.7537 53.2255C54.7435 53.2297 54.7265 53.2305 54.7231 53.2381C54.1119 54.4949 53.0688 54.6643 52.0598 53.6693C48.9238 50.5761 45.787 47.4845 42.6588 44.3845C42.4624 44.1899 42.3451 44.1555 42.1088 44.3375C40.3397 45.6992 38.3404 46.5708 36.1327 46.9459C33.3308 47.4216 30.6216 47.0969 28.0323 45.9123C25.2882 44.6563 23.1724 42.7183 21.7103 40.1133C20.3688 37.7223 19.8146 35.1492 20.0543 32.415C20.2974 29.6456 21.3116 27.1782 23.0848 25.0355C25.2449 22.4246 28.0255 20.8096 31.3944 20.2039C31.8475 20.1225 32.3065 20.0671 32.7621 20C33.4405 20 34.1197 20 34.7981 20C34.8822 20.0738 34.9893 20.0579 35.088 20.0654C36.9726 20.2215 38.739 20.7828 40.3814 21.6855C43.0268 23.1402 44.9973 25.2335 46.269 27.9542C47.4591 30.5005 47.783 33.1625 47.3044 35.916C46.9261 38.0948 46.0387 40.0655 44.6649 41.8139C44.4881 42.0388 44.4983 42.1562 44.7032 42.3576C47.8043 45.4038 50.8943 48.461 53.9929 51.5115C54.3074 51.8211 54.5964 52.1382 54.7528 52.5552V53.2247L54.7537 53.2255ZM44.6054 33.5778C44.6139 27.6874 39.7362 22.8726 33.7593 22.8718C27.7815 22.8718 22.9131 27.674 22.9123 33.5753C22.9106 39.4724 27.7841 44.283 33.7576 44.2813C39.7345 44.2796 44.5969 39.4824 44.6054 33.5778Z"
                        fill="white"
                      />
                    </svg>
                  </Link>
                </div>
              </form>
            ) : null}
          </div>
          <div className="hero__slide__content ">
            <h1 className="bannerHeading">
              <div className="count">
                <CountUp
                  className="count"
                  start={0}
                  end={jobNumber}
                  duration={3}
                />
                Verfügbare Jobs
              </div>

              <div className="count">
                <CountUp
                  className="count"
                  start={0}
                  end={employerCount}
                  duration={3}
                />
                REGISTRIERTE ARBEITGEBER
              </div>

              <div className="count">
                <CountUp
                  className="count"
                  start={0}
                  end={candidateCount}
                  duration={3}
                />
                REGISTRIERTE KANDIDATEN
              </div>
            </h1>
          </div>
          <div className="bannerWrapper">
            <h1 className="bannerTitles">{bannerHeading}</h1>
            <p>{bannerContent}</p>
          </div>
        </div>
      </section>
      {/* <!-- header-space --> */}
      <section className="normal">
        <div className="container">
          <div className="row">
            <div className="com-sec">
              <div className="com-text col-5">
                <span>{preHeadingSection}</span>
                <h3
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-offset="200"
                >
                  {headingSection}
                </h3>
                <div className="trade">
                  <div className="fly">
                    {processSection
                      ? processSection.map((item, i) => (
                          <div
                            className="blending"
                            data-aos="fade-right"
                            data-aos-duration="1000"
                            data-aos-offset="200"
                            data-aos-delay="500"
                            key={i}
                          >
                            <LazyLoad className="bending_img">
                              <img src={item.img} alt="" />
                            </LazyLoad>
                            <div className="imone">
                              <h4>{item.heading}</h4>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.content,
                                }}
                              />
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>

              <div
                className="img-wrapper col-6 slider_sec"
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-offset="200"
              >
                {/* <LazyLoad className="home_2_slider img-wrapper"> */}
                <Slider {...funcSettings}>
                  {imageSlider
                    .filter((img) => img !== null)
                    .map((ImgLink, index) => (
                      <div className="com-img" key={index}>
                        <img src={ImgLink} alt="home_2_slider" />
                      </div>
                    ))}
                </Slider>
                {/* </LazyLoad> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="majority-sec">
        <div className="container">
          <div className="row">
            <div className="majority-wrap">
              <div className="majority-content">
                <span>{preAboutHeading}</span>
                <h3>{aboutHeading}</h3>
                <p>{aboutContent}</p>

                <Link
                  to="/about"
                  className="btn btn-red custom-btn-link"
                  data-aos="fade-top"
                  data-aos-duration="1000"
                  data-aos-offset="200"
                >
                  MEHR LESEN
                </Link>
              </div>
              <div className="majority-img">
                {/* <LazyLoad className="about_sec majority-img"> */}
                <img
                  src={aboutImage}
                  alt="aboutImage"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-offset="200"
                />
                {/* </LazyLoad> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="preview-sec">
        <div className="container">
          <div className="row">
            <div className="preview-wrap">
              <div className="table-image">
                <LazyLoad className="interViewImg">
                  <img
                    src={interViewSectionImage}
                    alt="interView_img"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-offset="300"
                  />
                </LazyLoad>
                <h3>{interViewSectionheading}</h3>
              </div>
            </div>
            <div className="card-row desktop-view">
              <div
                className="two-cards-wrp"
                data-aos="fade-right"
                data-aos-duration="1000"
                data-aos-offset="300"
              >
                <div className="new-jobs">
                  <h2>Top Arbeitgeber</h2>
                  <div className="new-jobs-inside__overflowWrapper">
                    <div className="new-jobs-inside">
                      {topEmployerStatus ? (
                        topEmployer.map((item, i) => (
                          <a
                            href={`${process.env.REACT_APP_HOST_URL}employer/${item.employer_slug}`}
                            key={i}
                          >
                            <div className="jobs">
                              <img
                                src={
                                  item.profileIMG
                                    ? item.profileIMG
                                    : `${process.env.REACT_APP_HOST_URL}wp-content/uploads/2024/07/JobwishLogo.png`
                                }
                                alt=""
                              />
                              <div className="job-text">
                                <h3>{item.employer_Name}</h3>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item.employer_bio
                                      .split(" ")
                                      .slice(0, 7)
                                      .join(" "),
                                  }}
                                />
                              </div>
                            </div>
                          </a>
                        ))
                      ) : (
                        <div className="topempstatusmsg">{topEmployer}</div>
                      )}
                    </div>
                  </div>
                  <div className="card-bottom-btn">
                    <Link to={`/topemployer`} className="view">
                      Top Arbeitgeber anzeigen
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="two-cards-wrp-sec "
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-offset="300"
              >
                <div className="new-jobs">
                  <h2>Neueste Jobs</h2>
                  <div className="new-jobs-inside">
                    {Array.isArray(jobByDescOrder) &&
                    jobByDescOrder.length > 0 ? (
                      jobByDescOrder.map((item, i) => (
                        <a
                          href={`${process.env.REACT_APP_HOST_URL}job/${item.job_slug}`}
                          key={i}
                        >
                          <div className="jobs">
                            <div className="job-text">
                              <h3>{item.job_title}</h3>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.job_bio,
                                }}
                              />
                            </div>
                          </div>
                        </a>
                      ))
                    ) : (
                      <h2>No Jobs Available</h2>
                    )}
                  </div>

                  <div className="card-bottom-btn">
                    <Link to={`/allLatestJob`} className="view">
                      Neueste Jobs anzeigen
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="preview-sec preview-sec2 ">
        <div className="container">
          <div className="card-row ">
            <div
              className="two-cards-wrp"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-offset="300"
            >
              <div className="new-jobs">
                <h2>Top-Arbeitgeber</h2>
                <div className="new-jobs-inside">
                  {topEmployerStatus ? (
                    topEmployer.map((item, i) => (
                      <a
                        href={`${process.env.REACT_APP_HOST_URL}employer/${item.employer_slug}`}
                        key={i}
                      >
                        <div className="jobs">
                          <img
                            src={
                              item.profileIMG
                                ? item.profileIMG
                                : `${process.env.REACT_APP_HOST_URL}wp-content/uploads/2024/07/JobwishLogo.png`
                            }
                            alt=""
                          />
                          <div className="job-text">
                            <h3>{item.employer_Name}</h3>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item.employer_bio
                                  .split(" ")
                                  .slice(0, 7)
                                  .join(" "),
                              }}
                            />
                          </div>
                        </div>
                      </a>
                    ))
                  ) : (
                    <div className="topempstatusmsg">{topEmployer}</div>
                  )}
                </div>
                <div className="card-bottom-btn">
                  <Link to={`/topemployer`} className="view">
                    Top-Arbeitgeber anzeigen
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="two-cards-wrp-sec"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-offset="300"
            >
              <div className="new-jobs">
                <h2>Neueste Jobs</h2>
                <div className="new-jobs-inside">
                  {jobByDescOrder
                    ? jobByDescOrder.map((item, i) => (
                        <a
                          href={`${process.env.REACT_APP_HOST_URL}job/${item.job_slug}`}
                          key={i}
                        >
                          <div className="jobs">
                            <div className="job-text">
                              <h3>{item.job_title}</h3>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.job_bio,
                                }}
                              />
                            </div>
                          </div>
                        </a>
                      ))
                    : null}
                </div>
                <div className="card-bottom-btn">
                  <Link to={`/allLatestJob`} className="view">
                    Neueste Jobs anzeigen
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="download">
        <div className="container">
          <div className="row justify-content-center">
            <div className="download-wrap">
              <div className="img-side">
                <LazyLoad className="appImageLink">
                  <img
                    src={appImage}
                    alt="app_img"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-offset="200"
                  />
                </LazyLoad>
              </div>
              <div
                className="download-content  
                fadeInUp wow   animated
                "
                data-wow-duration="3s"
              >
                <span>{preHeading}</span>
                <h2>{heading}</h2>
                <p>{content}</p>
                <div className="download-channel">
                  <a href={appIOSLink} aria-label="IOSAppLink">
                    <img
                      src={iosAppLinkImg}
                      alt=""
                      className="appstore"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-offset="200"
                    />
                  </a>
                  <a href={appAndroidLink} aria-label="androidAppLink">
                    <img
                      src={androidAppLinkImg}
                      alt=""
                      className="google-store"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-offset="200"
                      data-aos-delay="500"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Home;
