import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazy-load";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";

function FAQ() {
  const [faqPage, setFAQPage] = useState();
  let [FAQBannerImage, setFAQBannerImage] = useState();
  let [FAQBannerheading, setFAQBannerHeading] = useState();
  let [maninHeading, setMainHeading] = useState();
  let [lastSecImage, setLastSecImage] = useState();
  let [lastSecImageLink, setLastSecImageLink] = useState();
  let [lstSecHeading, setlastSecHeading] = useState();
  let [lastSecContent, setlastSecContent] = useState();
  let [buttonTitle, setButtonTitle] = useState();
  let [buttonLink, setButtonLink] = useState();
  const [faqCPT, setFAQCpt] = useState([]);
  const [activeAccordion, setActiveAccordion] = useState(0);

  useEffect(() => {
    const faq_page_images = async () => {
      let faqPageImageResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}/wp-json/jobwish/v1/imagesForWeb`
      );
      let imagesResponse = await faqPageImageResponse.json();
      setFAQBannerImage(imagesResponse.data.FAQ_page);
    };
    setTimeout(() => {
      faq_page_images();
    }, 200);
  }, []);

  useEffect(() => {}, [FAQBannerImage]);

  useEffect(() => {
    const faqpageData = async () => {
      // try {
      let faqpageResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/pages/554`
      );
      let faqpageResponseData = await faqpageResponse.json();
      setFAQPage(faqpageResponseData);
      // } catch (error) {
      //   console.log("Error :", error);
      // }
    };
    setTimeout(() => {
      faqpageData();
    }, 300);
  }, []);

  useEffect(() => {
    const faqCPTData = async () => {
      try {
        let faqCPTResponse = await fetch(
          `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/faq`
        );
        let faqCPTResponseData = await faqCPTResponse.json();
        setFAQCpt(faqCPTResponseData);
      } catch (error) {
        console.log("Error :", error);
      }
    };
    setTimeout(() => {
      faqCPTData();
    }, 400);
  }, []);

  useEffect(() => {
    if (faqPage) {
      console.log(faqPage);
      // setFAQBannerImage(faqPage.featured_media);
      setFAQBannerHeading(faqPage.title.rendered);
      setMainHeading(faqPage.acf.heading);
      setLastSecImage(faqPage.acf.still_have_ques.image);
      setlastSecHeading(faqPage.acf.still_have_ques.heading);
      setlastSecContent(faqPage.acf.still_have_ques.content);
      setButtonTitle(faqPage.acf.still_have_ques.get_in_touch.title);
      setButtonLink(faqPage.acf.still_have_ques.get_in_touch.url);
    }
  }, [faqPage]);

  useEffect(() => {
    if (lastSecImage) {
      const lastSecImageLinkData = async () => {
        // try {
        let lastSecImgLinkResponse = await fetch(
          `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/media/${lastSecImage}`
        );
        let lastSecImgLink = await lastSecImgLinkResponse.json();
        setLastSecImageLink(lastSecImgLink.guid.rendered);
        // } catch (error) {
        //   console.log("error :", error);
        // }
      };
      setTimeout(() => {
        lastSecImageLinkData();
      }, 500);
    }
  }, [lastSecImage]);

  useEffect(() => {}, [lastSecImageLink]);

  useEffect(() => {
    // if (faqCPT) {
    //   // console.log(faqCPT);
    // }
  }, [faqCPT]);

  useEffect(() => {
    AOS.init({ startEvent: "load" });
  }, []);

  const handleAccordionClick = (index) => {
    setActiveAccordion(index === activeAccordion ? null : index);
  };

  return (
    <>
      <Helmet>
        <title>Häufig gestellte Fragen zum Thema Karriere – Jobwish</title>
        <meta
          name="description"
          content="Finden Sie auf Jobwish schnelle Antworten auf Ihre Fragen zur Jobsuche. Holen Sie sich noch heute professionelle Karrieretipps und Beratung!"
        />
      </Helmet>
      <section className="faq blog-hero">
        {/* <LazyLoad className="FAQImg blog-hero"> */}
        <img src={FAQBannerImage} alt="" loading="eager" />
        {/* </LazyLoad> */}
        <div className="bnr-blog-content">
          <h1>{FAQBannerheading}</h1>
        </div>
      </section>

      <section className="breadcb faq-breadcb">
        <div className="container">
          <div className="row">
            <div className="breadcrumb">
              <span property="itemListElement" typeof="ListItem">
                <a
                  property="item"
                  typeof="WebPage"
                  title="Categories"
                  href="/categories/"
                  className="archive post-product-archive"
                >
                  <span property="name">Startseite</span>
                </a>
                <meta property="position" content="1" />
              </span>
              <div className="bacsp">/</div>
              <span property="itemListElement" typeof="ListItem">
                <span
                  property="name"
                  className="archive taxonomy product_cat current-item"
                >
                  FAQ
                </span>
                <meta
                  property="url"
                  content="https://bromicpgstg.wpengine.com/product-category/gas-components/"
                />
                <meta property="position" content="2" />
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="blogs faq">
        <div className="container">
          <div className="row">
            <h2>{maninHeading}</h2>

            <div className="blogs__main blog-faqs">
              <div className="blogs__right cta-accordian">
                <div className="accordion faqs-accordian">
                  {faqCPT.map((listItem, i) => (
                    <div
                      className={`accordion-item ${
                        i === activeAccordion ? "icon-active" : ""
                      }`}
                      key={i}
                    >
                      <div
                        className="accordion-button-1 faq-accordian"
                        onClick={() => handleAccordionClick(i)}
                      >
                        <p>{listItem.title.rendered}</p>
                      </div>
                      <div
                        className={`accordion-content ${
                          i == activeAccordion ? "active" : "none_active"
                        }`}
                        // style={{
                        //   display: i === activeAccordion ? "block" : "none",
                        // }}
                      >
                        <p
                          dangerouslySetInnerHTML={{
                            __html: listItem.content.rendered,
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq-question">
        <div className="container p-0">
          <div className="">
            <div className="col-lg-12">
              <div className="cta-ques">
                <div className="content">
                  <img
                    src={lastSecImageLink}
                    alt=""
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-offset="200"
                  />
                  <h2>{lstSecHeading}</h2>
                  <p>{lastSecContent}</p>
                  <a
                    href={buttonLink}
                    className="ques-btn"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-offset="200"
                  >
                    {buttonTitle}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M5 12H19"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 5L19 12L12 19"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FAQ;
