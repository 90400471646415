import React, { useEffect, useState } from "react";
// import LazyLoad from "react-lazy-load";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";

function Blog() {
  const [blogPage, setBlogpage] = useState();
  let [blogBannerHeading, setblogBannerHeading] = useState();
  let [blogBannerImg, setBlogbannerImg] = useState();
  const [blogpost, setBlogPost] = useState([]);
  // const [servicesData, setServicesData] = useState({});

  // const navigate = useNavigate();

  useEffect(() => {
    let blogPageData = async () => {
      // try {
      let blogPageResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/pages/544`
      );
      let blogPageResponseData = await blogPageResponse.json();
      setBlogpage(blogPageResponseData);
      // } catch (error) {
      //   console.log("Error :", error);
      // }
    };
    blogPageData();
  }, []);

  useEffect(() => {
    if (blogPage) {
      // console.log(blogPage);
      setblogBannerHeading(blogPage.title.rendered);
    }
  }, [blogPage]);

  useEffect(() => {
    const blog_page_images = async () => {
      let blogPageImageResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}/wp-json/jobwish/v1/imagesForWeb`
      );
      let imageResponse = await blogPageImageResponse.json();
      setBlogbannerImg(imageResponse.data.Blog_page);
    };
    setTimeout(() => {
      blog_page_images();
    }, 300);
  }, []);

  // useEffect(()=>{},[blogBannerImg]);

  useEffect(() => {
    let blogpostSec = async () => {
      let blogPostResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/blogPost`
      );
      let blogPostData = await blogPostResponse.json();
      setBlogPost(blogPostData.data);
    };
    setTimeout(() => {
      blogpostSec();
    }, 400);
  }, []);

  useEffect(() => {
    AOS.init({ startEvent: "load" });
  }, []);

  return (
    <>
      <Helmet>
        <title>Entdecken Sie Karriereeinblicke mit dem Jobwish-Blog</title>
        <meta
          name="description"
          content="Entdecken Sie professionelle Karrieretipps, Branchentrends und Einblicke in den Arbeitsmarkt im Jobwish-Blog, stärken Sie Ihre Karriere – besuchen Sie uns jetzt!"
        />
      </Helmet>
      <section className="blog-hero">
        {/* <LazyLoad className="BlogBanrImg blog-hero"> */}
        <img src={blogBannerImg} alt="" loading="eager" />
        {/* </LazyLoad> */}
        <div className="bnr-blog-content">
          <h1>{blogBannerHeading}</h1>
        </div>
      </section>

      <section className="breadcb faq-breadcb">
        <div className="container">
          <div className="row">
            <div className="breadcrumb">
              <span property="itemListElement" typeof="ListItem">
                <a
                  property="item"
                  typeof="WebPage"
                  title="Categories"
                  href="/categories/"
                  className="archive post-product-archive"
                >
                  <span property="name">Startseite</span>
                </a>
                <meta property="position" content="1" />
              </span>
              <div className="bacsp">/</div>
              <span property="itemListElement" typeof="ListItem">
                <span
                  property="name"
                  className="archive taxonomy product_cat current-item"
                >
                  Blogs
                </span>
                <meta
                  property="url"
                  content="https://bromicpgstg.wpengine.com/product-category/gas-components/"
                />
                <meta property="position" content="2" />
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="blog">
        <div className="container">
          <div className="row">
            <div className="blog-card-wrap">
              {blogpost.map((item, i) => (
                <div
                  className="blog-card"
                  key={i}
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay={`${i * 2}00`}
                  data-aos-offset="100"
                >
                  <div className="blog-img">
                    {/* <LazyLoad className="PostImg blog-img"> */}
                    <a href={`${process.env.REACT_APP_HOST_URL}${item.slug}`}>
                      <img src={item.img} alt="" />
                    </a>
                    {/* </LazyLoad> */}
                  </div>
                  <div className="blog-content">
                    <span>{item.time ? item.time.split("T")[0] : null}</span>
                    <a href={`${process.env.REACT_APP_HOST_URL}${item.slug}`}>
                      <h5>{item.heading}</h5>
                    </a>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.content,
                      }}
                    />
                    {/* <Link
                      className="read-more2"
                      to={`/blogs/${item.slug}/${item.PostId}`}
                      value={item.PostId}
                    >
                      Mehr lesen
                    </Link> */}
                    <a
                      href={`${process.env.REACT_APP_HOST_URL}${item.slug}`}
                      className="read-more2"
                    >
                      Mehr lesen
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Blog;
