import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazy-load";
import Slider from "react-slick";

function Testimonial() {
  const [ContentData, setContentData] = useState("");
  let [sponsordImage, setSponsordImage] = useState("");
  let [heading, setHeading] = useState("");
  let [subContent, setSubContent] = useState("");
  let [subscribeHeading, setSubscribeHeading] = useState("");
  let [subscribeContent, setSubscribeContent] = useState("");

  let [testimonialPost, setTestimonialPost] = useState([]);

  const iframeUrl = `${process.env.REACT_APP_HOST_URL}/newsletter/`;

  useEffect(() => {
    const ContentDataResponse = async () => {

      const dataResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/testimonialcomponent`
      );
      const data = await dataResponse.json();
      setContentData(data);

    };
    setTimeout(()=>{
      ContentDataResponse()
    },3500);
    // ContentDataResponse();
  }, []);

  useEffect(() => {
    if (ContentData && ContentData.data) {
      // console.log("ContentACF :" , ContentData.acf);
      setSponsordImage(ContentData.data.sponsored_image);
      setHeading(ContentData.data.heading);
      setSubContent(ContentData.data.sub_content);
      setSubscribeHeading(ContentData.data.subscribe_section.heading);
      setSubscribeContent(ContentData.data.subscribe_section.content);
    }
  }, [ContentData]);


  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const testimonialResponse = await fetch(
          `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/web-testimonial-post`
        );
        const testimonialData = await testimonialResponse.json();

        if (Array.isArray(testimonialData.data)) {
          setTestimonialPost(testimonialData.data);
        } else {
          // console.error("Invalid testimonial data format:", testimonialData);
        }
      } catch (error) {
        // console.error("Error fetching testimonials:", error);
      }
    };
    setTimeout(()=>{fetchTestimonials()},4000);
    // fetchTestimonials();
  }, []);

  useEffect(() => {}, [testimonialPost]);

  //////////////  sitelogo  slider /////

  const sitelogosettings = {
    dots: false,
    arrows: false,
    infinite: true,
    loop: true,
    speed: 1500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnFocus: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          dots: true,
        },
      },
    ],
  };

  //////////    Testimonial Slider //////////
  const testimonialsettings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    loop: true,
    speed: 1500,
    autoplay: false,
    autoplaySpeed: 3000,
    pauseOnFocus: false,
    centerMode: false,
    //centerPadding:'30px',
    responsive: [
      {
        breakpoint: 1401,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="logo">
        <div className="container">
          <div className="logo-wrapper">
            {/* <LazyLoad className="logoImgs"> */}
            <Slider {...sitelogosettings}>
              {sponsordImage
                ? sponsordImage.map((ImgLink, index) => (
                    <div className="site-logo" key={index}>
                      <img key={index} src={ImgLink.img} alt="" loading="eager" />
                    </div>
                  ))
                : null}
            </Slider>
            {/* </LazyLoad> */}
          </div>
        </div>
      </section>

      <section className="clients">
        <div className="container">
          <div className="main-heading">
            <h2>{heading}</h2>
            <p>{subContent}</p>
          </div>
          <div className="card-wrapper threcardrow">
            <Slider {...testimonialsettings}>
              {testimonialPost.map((testimonial, index) => (
                <div
                  className="client-cards"
                  key={index}
                  style={{ marginRight: "10px" }}
                >
                  <div className="cards">
                    <div className="info">
                      <LazyLoad className="client-cards-imgs">
                        <img src={testimonial.profile_image} alt="" />
                      </LazyLoad>
                      <div className="details">
                        <h3>{testimonial.title}</h3>
                        <span>{testimonial.job_position}</span>
                      </div>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{ __html: testimonial.content }}
                    />
                    <div className="stars">
                      {Array.from(
                        { length: testimonial.star_rating },
                        (_, index) => (
                          <svg
                            key={index}
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                          >
                            <path
                              d="M6.56163 0.917969L8.04068 5.34974L12.7127 5.3177L8.95371 8.09423L10.4328 12.526L6.6257 9.8082L2.86672 12.5847L4.27635 8.12627L0.474609 5.40847L5.14666 5.37643L6.56163 0.917969Z"
                              fill="#B21918"
                            />
                          </svg>
                        )
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>

      <section className="bottom-footer">
        <div className="bottom-footer-cotainer">
          <div className="row justify-content-center">
            <div className="footer-newsletter">
              <div className="col-md-6 col-sm-12 col-12">
                <div className="content text-start">
                  <h3>{subscribeHeading}</h3>
                  <p>{subscribeContent}</p>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-12">
                <iframe
                  title="Newsletter Subscription"
                  src={iframeUrl}
                  width="100%"
                  height="auto"
                  frameBorder="0"
                  scrolling="no"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonial;
